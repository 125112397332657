import React from "react"
import TbxLogo from "../../icon/tbxLogo"
import { Col, Container, Row } from "react-bootstrap"

const TbxInfo = () => {
  return (
    <section className="cs-info position-relative pb-0">        
        <Container className="position-relative">
            <Row className="pb-md-5 pb-0">
                <Col lg={8}>
                    <h1 className="cs-title px-0 col-md-8 u-section mb-4 text-white">Building a marketplace for the trading of commodities.</h1>
                    <p className="cs-sub-text mb-4 text-white">TBX is a trading app that facilitates the trading of perishable commodities. This platform enables people to trade and exchange commodities.</p>
                    <Col md={8} className="details-mini d-flex justify-content-between px-0">
                        <div className="stats-info">
                            <p className="cs-sub-text st-title mb-0 text-white">Duration</p>
                            <p className="st-data mb-0 text-white">4 Months</p>
                        </div>
                        <div className="stats-info">
                            <p className="cs-sub-text st-title mb-0 text-white">Services</p>
                            <p className="st-data mb-0 text-white">UX/UI</p>
                        </div>
                        <div className="stats-info">
                            <p className="cs-sub-text st-title mb-0 text-white">Platform</p>
                            <p className="st-data mb-0 text-white">iOS, Android</p>
                        </div>												
                    </Col>                
                </Col>
                <Col lg={3} className="offset-lg-1 text-lg-end mt-lg-0 mt-md-5 mt-5">
                    <div className="position-relative">
                        <TbxLogo />
                    </div>
                </Col>
            </Row>
        </Container>
    </section>
  )
}

export default TbxInfo
