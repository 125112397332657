import React from "react"
import SeoHead from "../../components/global/seoHead"
import Layout from "../../components/global/layout"
import Seo from "../../components/global/seo"

import ScrollIndicationHeightZero from "../../components/global/scrollIndicationHeightZero"
import TbxMain from "../../components/case-studies/tbx-trading-app/tbxMain"
import TbxInfo from "../../components/case-studies/tbx-trading-app/tbxInfo"
import TbxInfoImage from "../../components/case-studies/tbx-trading-app/tbxInfoImage"
import TbxAbout from "../../components/case-studies/tbx-trading-app/tbxAbout"
import TbxCustomToken from "../../components/case-studies/tbx-trading-app/tbxCustomToken"
import TbxFullWidth1 from "../../components/case-studies/tbx-trading-app/tbxFullWidth1"
import TbxExchange from "../../components/case-studies/tbx-trading-app/tbxExchange"
import TbxFullWidth2 from "../../components/case-studies/tbx-trading-app/tbxFullWidth2"
import TbxAddingCoins from "../../components/case-studies/tbx-trading-app/tbxAddingCoins"
import TbxMoreCs from "../../components/case-studies/tbx-trading-app/tbxMoreCs"

import DirectContact from "../../components/global/directContact"

import csTbxFeaturedImage from "../../../static/case-studies/tbx-trading-app/tbx-featured-thumbnail.png"

import "../../sass/pages/case-studies/cs-global.scss"
import "../../sass/pages/case-studies/tbx-trading-app.scss"

export const Head = () => (
  <>
    <SeoHead
      ogImage={csTbxFeaturedImage}
      title="TBX Trading App Case Study | UX/UI Design | Financial Services"
      description="How we helped TBX build a marketplace to trade perishable commodities."
    />
  </> 
)

const TbxTradingApp = () => {
    return (
      <Layout>
        <Seo
          bid="cs-tbx-app"
          bclass="case-studies-page"
        ></Seo>
        <ScrollIndicationHeightZero />
        <TbxMain />
        <TbxInfo />
        <TbxInfoImage />
        <TbxAbout />
        <TbxCustomToken />
        <TbxFullWidth1 />
        <TbxExchange />
        <TbxFullWidth2 />
        <TbxAddingCoins />
        <TbxMoreCs />
        <DirectContact />
      </Layout>
    )
}

export default TbxTradingApp