import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { Col, Container, Row } from "react-bootstrap"
import TbxBottomPattern from "../../icon/tbxBottomPattern"

const TbxAbout = () => {
  return (
    <section className="about-tbx position-relative">        
        <Container>
            <Row className="align-items-center">
                <Col lg={7}>
                    <StaticImage src="../../../images/case-studies/tbx-trading-app/tbx-components.png" alt="TBX Trading App WowMakers Case study Header" placeholder="blurred" className="img-fluid cs-image-min-width" width={814} height={545}/>
                </Col>
                <Col lg={4}>
                    <h2 className="mb-4">A platform to earn money by trading commodities</h2>
                    <p className="cs-sub-text">a promising idea that offers ample scope to earn money. We opted for a minimalistic style for this platform as the app would be more about numbers, graphs, and  transactions.</p>                    
                </Col>
            </Row>
        </Container>
        <div className="bottom-patern">
            <TbxBottomPattern />
        </div>        
    </section>
  )
}

export default TbxAbout
