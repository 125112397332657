import React from "react";

function TbxBottomPattern() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1440"
      height="517"
      fill="none"
      viewBox="0 0 1440 517"
    >
      <g style={{ mixBlendMode: "difference" }} opacity="0.2">
        <path
          fill="#2B2A3F"
          fillOpacity="0.4"
          d="M-12 8.96L341.644 153.05 720.154.521l377.956 152.528 354.2-144.088v75.962l-354.2 144.691-377.956-153.13-378.51 153.13L-12 84.924"
        ></path>
        <path
          fill="#2B2A3F"
          fillOpacity="0.6"
          d="M-12 152.445l353.644 144.088 378.51-152.528 377.956 152.528 354.2-144.088v76.566l-354.2 144.088L720.154 220.57l-378.51 152.529L-12 229.011"
        ></path>
        <path
          fill="#232233"
          d="M-12 295.931l353.644 144.691 378.51-153.131 377.956 153.131 354.2-144.691v76.566l-354.2 144.088-377.956-152.528-378.51 152.528L-12 372.497"
        ></path>
      </g>
    </svg>
  );
}

export default TbxBottomPattern;
