import React from "react";

function TbxLogo() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="139"
      height="89"
      fill="none"
      viewBox="0 0 139 89"
    >
      <path
        fill="#fff"
        d="M13.673 88.483V9.127H0V.773h36.42v8.354H22.829v79.356h-9.155zM49.255 88.483V.773h28.717c1.976 0 3.63.653 4.92 1.958 1.291 1.305 1.937 2.959 1.937 4.96v25.582c0 2.523-1.13 4.655-3.388 6.352l-5.002 3.045 5.405 3.48c2.097 1.306 3.186 3.394 3.186 6.179v29.28c0 2.001-.645 3.698-1.936 4.96-1.29 1.305-2.944 1.957-4.92 1.957h-28.92v-.043zm26.58-8.354V51.546l-7.14-4.656H58.411v33.283h17.423v-.044zm-7.26-41.505l7.058-4.786V9.127H58.41v29.497h10.163zM129.008 88.483l-10.849-33.63-10.85 33.63h-9.438l15.649-44.9C99.162 1.819 99.04.774 99.04.774h9.56l9.68 30.934L128.363.773h9.478l-.524 1.436-14.157 40.983a77192.486 77192.486 0 0115.528 45.334h-9.68v-.043z"
      ></path>
    </svg>
  );
}

export default TbxLogo;