import React from "react"
import { Col, Container, Row } from "react-bootstrap"

const TbxExchange = () => {
  return (
    <section className="d-exchange">        
        <Container>
            <Row>
                <Col lg={5}>
                    <h2 className="sub-title">Designing an exchange to trade agricultural goods.</h2>
                </Col>
                <Col lg={6} className="offset-lg-1">
                    <p className="cs-sub-text">
                        TBX makes it easy for people to trade perishable commodities, and earn money out of it seamlessly. Since the app will primarily be used for data, graphs, and transactions, we chose a minimalist design for this platform. 
                    </p>                    
                </Col>                
            </Row>
        </Container>
    </section>
  )
}

export default TbxExchange
