import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { Col, Container, Row } from "react-bootstrap"

const TbxInfoImage = () => {
  return (
    <section className="cs-info-image d-flex align-items-end">        
        <Container>
            <Row className="justify-content-center">
                <Col lg={9} className="position-relative">
                    <div className="info-image text-center">
                        <StaticImage src="../../../images/case-studies/tbx-trading-app/cs-info-full-width.png" alt="TBX Trading App WowMakers Case study Header" placeholder="blurred" className="img-fluid cs-image-min-width" width={1185} height={857}/>
                    </div>
                </Col>
            </Row>
        </Container>
    </section>
  )
}

export default TbxInfoImage
