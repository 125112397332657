import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { Col, Container, Row } from "react-bootstrap"

const TbxCustomToken = () => {
  return (
    <section className="custom-token-tbx">        
        <Container>
            <Row className="justify-content-center">
                <Col lg={8} className="text-center">
                    <h2 className="mb-4">Custom token design for TBX</h2>
                    <p className="cs-sub-text">We designed TBX coins, the reward coins that the users would earn while using the app. The coin was designed by blending two elements - the letter T from TBX and lines positioned as wings on either edge of the coin to signify growth and positivity.</p>                    
                </Col>                
            </Row>
            <Row className="mt-5">
                <Col xs={4}>
                    <StaticImage src="../../../images/case-studies/tbx-trading-app/custom-token-1.png" alt="TBX Trading App WowMakers Case study Header" placeholder="blurred" className="img-fluid cs-image-min-width" width={424} height={416}/>
                </Col>
                <Col xs={4}>
                    <StaticImage src="../../../images/case-studies/tbx-trading-app/custom-token-2.png" alt="TBX Trading App WowMakers Case study Header" placeholder="blurred" className="img-fluid cs-image-min-width" width={424} height={416}/>
                </Col>
                <Col xs={4}>
                    <StaticImage src="../../../images/case-studies/tbx-trading-app/custom-token-3.png" alt="TBX Trading App WowMakers Case study Header" placeholder="blurred" className="img-fluid cs-image-min-width" width={424} height={416}/>
                </Col>
                <Col xs={12} className="mt-4">
                    <StaticImage src="../../../images/case-studies/tbx-trading-app/custom-token-4.png" alt="TBX Trading App WowMakers Case study Header" placeholder="blurred" className="img-fluid cs-image-min-width" width={1320} height={480}/>
                </Col>                                                
            </Row>
        </Container>
    </section>
  )
}

export default TbxCustomToken
