import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { Col, Container, Row } from "react-bootstrap"

const TbxAddingCoins = () => {
  return (
    <section className="adding-tbx-coins">        
        <Container>
            <Row className="justify-content-center text-center">
                <Col md={12}>
                    <h2  className="sub-title text-white">Adding TBX Coins</h2>
                </Col>
                <Col md={4} className="mt-5">
                    <StaticImage src="../../../images/case-studies/tbx-trading-app/tbx-coins-img.png" alt="TBX Trading App WowMakers Case study Header" placeholder="blurred" className="img-fluid cs-image-min-width" width={359} height={759}/>
                </Col>                
            </Row>
        </Container>
    </section>
  )
}

export default TbxAddingCoins
