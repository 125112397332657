import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { Col, Container, Row } from "react-bootstrap"

const TbxFullWidth1 = () => {
  return (
    <section className="tbx-full-width py-0">        
        <Container fluid>
            <Row>
                <Col lg={12} className="px-0">
                    <StaticImage src="../../../images/case-studies/tbx-trading-app/tbx-fulll-width-1.png" alt="TBX Trading App WowMakers Case study Header" placeholder="blurred" className="img-fluid" width={1440} height={773}/>
                </Col>                
            </Row>
        </Container>
    </section>
  )
}

export default TbxFullWidth1
